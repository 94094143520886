.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero {
  /* background-image: url("../public/img/background_gray.png"); */
  background-color: #282c34;
  min-height: 25vh;
  font-size: calc(10px + 2vmin);
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.font_comfortaa{
  font-family: "Comfortaa";
}